import React, { useContext, MouseEvent } from 'react';
import { navigate } from 'gatsby';
import appService from '../../../services/endpoints';
import Layout from '../../../components/287634/layout/layout';
import Button from '../../../components/287634/button/button';
import { H2, H4, P } from '../../../components/287634/typography';
import { CompanyContext } from '../../../context/company.context';

const EmailCodePage = ({ getToken }: PageProps) => {
  const token = getToken();
  const { getPublicURL } = useContext(CompanyContext);

  const resend = (event: MouseEvent) => {
    event.preventDefault();
    // @todo: should handle response?
    appService.resendEmailCode(token);
  };

  return (
    <Layout>
      <section className="mx-5 text-center text-primary">
        <H2 className="mt-[60px] mb-5">Código de seguridad</H2>
        <P className="mb-5">¡Se envió un email con el número para recuperar tu código de seguridad!</P>
        <img className="mx-auto mb-5 w-64" alt="Easy" src={getPublicURL(`/theme/assets/images/status/easy.svg`)} />
      </section>
      <section className="mx-10 text-center">
        <H4 className="mb-5 text-gray">
          Si no recibiste el correo para validar tu email, haz click en{' '}
          <a className="underline hover:text-primary" href="#Reenviar" onClick={resend}>
            reenviar email
          </a>
          .
          <br />
          Recuerda revisar la carpeta de SPAM.
        </H4>
        <Button
          className="mx-auto"
          onClick={() => {
            navigate(`./enter?${token}`);
          }}
        >
          Ingresar número
        </Button>
      </section>
    </Layout>
  );
};

export default EmailCodePage;
